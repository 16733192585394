"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export default function Error(props: { error: string; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(props.error);
  }, [props.error]);
  return (
    <html>
      <body>
        <div className="mx-auto m-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 dark:border-neutral-800 dark:bg-black md:p-12">
          <h2 className="text-xl font-bold">Hiba történt!</h2>
          <p className="my-2">
            Probléma merült fel áruházunkkal. Ez lehet, hogy csak átmeneti hiba,
            kérjük, próbálja meg újra a műveletet.
          </p>
          <button
            className="mx-auto mt-4 flex w-full items-center justify-center rounded-full bg-blue-600 p-4 tracking-wide text-white hover:opacity-90"
            onClick={() => props.reset()}
          >
            Újra
          </button>
        </div>
      </body>
    </html>
  );
}
